import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./App.css";
import "./bottom_effect.js";
import Layout from "./components/Shared/Layout";
import Login from "./components/Authentication/login";
import Logout from "./components/Authentication/logout";
import ForgotPassword from "./components/Authentication/forgotpassword";
import HomeScreen from "./components/Authentication/homeScreen";
import DetailScreen from "./components/Authentication/detailScreen";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { pingServer } from "./actions/auth";
import { bindActionCreators } from "redux";
import { PrivateRoute, PublicRoute } from "./route.js";
import ResetPassword from "./components/Authentication/resetPassword";
import Header from "./components/resuableComponent/header";
import SideNav from "./components/resuableComponent/sidenav";
import BottomNav from "./components/resuableComponent/bottomnav";
import RightNav from "./components/resuableComponent/rightnav";
import { isLogin } from "./utils/utils";
import Map from "./components/Dashboard/map.js";
import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
const TRACKING_ID = "G-0QRW9JJ6K7"; // your Measurement ID

const App = (props) => {
  //   constructor(props) {
  //     super(props);
  //     state = {
  //       route: window.location.pathname,
  //     };
  //     props.pingServer(props);
  //   }
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    console.log("ping server api");
    props.pingServer();
  }, [false]);

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const modeValue = params.get('mode');
  console.log(location.pathname,'location')

  const [route, setRoute] = useState(location.pathname);
  const { match } = location;

  useEffect(() => {
    const pathname = location?.pathname;
    setRoute(pathname);
  }, [location.pathname]);

  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );


  const { loading } = props.auth;

  const { themeColor, fontStyle, lightVersion, RtlVersion, offcanvas, miniSidebar, horizontalMenu, miniHover } = props.settings;
  document.getElementsByTagName("body")[0].className = `${themeColor} ${fontStyle}${lightVersion ? " light_version" : ""}${RtlVersion ? " rtl" : ""}${offcanvas ? " offcanvas-active" : ""
    }${horizontalMenu ? " h-menu" : ""}${miniSidebar ? " mini_sidebar" : ""}${miniHover ? " mini_hover" : ""}`;


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = () => width <= 768;
  return !isMobile() ? (
    <div className="detail_screen">
      {isLogin() && props?.auth?.user?.passwordReset && (
        <>
          <Header type={route} />
          {route !== "/summary" && route !== "/" && route !== "/login" && location.pathname.replace('/', '') !== 'profile' && <SideNav type={route} />}
          {route !== "/summary" && route !== "/" && route !== "/login" && modeValue !== "settings-mode" && location.pathname.replace('/', '') !== 'services' && location.pathname.replace('/', '') !== 'profile' && <BottomNav type={route} />}
          {route !== "/login" && location.pathname.replace('/', '') !== 'services' && location.pathname.replace('/', '') !== 'profile'
          && clientType == "Admin" && <RightNav type={route} />}
        </>
      )}


      {!loading && (
        <Switch>
          <PublicRoute auth={props.auth} restricted={true} path="/login" component={Login} exact />
          <PublicRoute auth={props.auth} restricted={false} path="/new-login" component={Logout} exact />
          <PublicRoute auth={props.auth} restricted={false} path="/forgotpassword" component={ForgotPassword} exact />
          <PublicRoute auth={props.auth} restricted={false} path="/homescreen" component={HomeScreen} exact />
          <PublicRoute auth={props.auth} restricted={false} path="/detailscreen" component={DetailScreen} exact />
          <PrivateRoute auth={props.auth} restricted={false} path="/reset-password" component={ResetPassword} exact />
          <PublicRoute auth={props.auth} restricted={false} path="/reset-password/:reset_code" component={ResetPassword} exact />
          <PrivateRoute auth={props.auth} path="/" match={match} component={Layout} />
        </Switch>
      )}
    </div>
  ) : (
    <div className="alert_screen">
      <span>Application is not compatible with your device.</span>
    </div>
  );
};

function mapStateToProps({ auth, settings }) {
  return {
    auth,
    settings,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      pingServer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
