import axios from "axios";
import { API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import {
  CODE_CREATED,
  CREATING_CODE_RECORD,
  LEAKEDCREDS_REQUESTS_FETCHED,
  LEAKED_SESSION_FETCHED_GRAPH,
  
} from "./index";
import { toastr } from "react-redux-toastr";
// import { toastr } from 'react-redux-toastr';



export const fetchLeakedCode = (props, dispatch ,page ,category, status) => {
  if(props){
    axios
      .get(`${API_URL}/approved-code/${props}/delta?page=${page}&category=${category}&status=${status}`, {
        params: {
          orgId: props,
        },
      })
      .then((response) => {
        //console.log(response);
        dispatch({
          type: "SET_LEAKED_CODE",
          data: response.data,
        });
      })
  }
};

export const fetchLeakedCodeAction = (props,dispatch , page, category ,data) => {
  if(props){
    axios
      .post(`${API_URL}/approved-code/${props}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: "SET_LEAKED_CODE",
          data: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
};

export const fetchLeakedCodeGraph = (props,dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/approved-code/${props}/graph`)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: "SET_LEAKED_CODE_GRAPH",
          payload: response.data,
        });
      })
  }
};



export const createdLeakedCodeRecord = (props, clear, dispatch) => {
  if (props) {
    //console.log(props);
    axios
      .post(`${API_URL}/leakedcode`, {
        repoUrl: props.repoUrl,
        organisationId: props.organisationId,
        programmingLanguage: props.programmingLanguage,
        severityLevel: props.severityLevel,
        leakedCode: props.leakedCode,
        comments: props.comments,
        attachmentUrl: props.attachmentUrl,
        approved: true
      })
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data?.errors[0]?.msg || response.data.userMessage);
        } else {
          clear();
          toastr.success("Success", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: CODE_CREATED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const createDarkWebRecord = (props, clear, dispatch) => {
  if (props) {
    //console.log(props);
    axios
      .post(`${API_URL}/darkWebRecord`, {
        WebUrl: props.darkWebUrl,
        organisationId: props.organisationId,
        WebDetails: props.darkWebDetails,
        WebSeverity: props.darkWebSeverity,
        WebMentionType: props.darkWebMentionType,
        WebComments: props.darkWebComments,
        WebAttachmentUrl: props.WebAttachmentUrl
      })
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data?.errors[0]?.msg || response.data.userMessage);
        } else {
          clear();
          toastr.success("Success", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: CODE_CREATED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const createAppSToreRecord = (props, clear, dispatch) => {
  if (props) {
    //console.log(props);
    axios
      .post(`${API_URL}/appStore`, props)
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data?.errors[0]?.msg || response.data.userMessage);
        } else {
          clear();
          toastr.success("Success", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: CODE_CREATED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const createSocialMediaProfile = (props, clear, dispatch) => {
  if (props) {
    //console.log(props);
    axios
      .post(`${API_URL}/createSocialMediaProfile`, props)
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data?.errors[0]?.msg || response.data.userMessage);
        } else {
          clear();
          toastr.success("Success", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: CODE_CREATED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};