import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, googleSignIn, verifyOTP } from "../../actions/auth";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import googleImage from "../../assets/images/googleImage.png";

var Model = require("./model");

class Login extends Component {
  state = {
    user: null,
    visiblePassword: false,
    authMethod: null,
    loading: false,
    showPassword: false,
    setOtp: ''
  };

  componentDidUpdate() {
    if (this.props.auth.user && this.state.loading) {
      this.setState({
        user: this.props.auth.user,
        loading: false,
      });
      if (this.props.auth.user.passwordReset) {
        if (this.props.auth.user.accessLevel == "Client")
          this.props.history.push("/");
        else if (
          this.props.auth.user.accessLevel == "MSSP" ||
          this.props.auth.user.accessLevel == "Threat Analyst"
        )
          this.props.history.push("/client");
        else {
          this.props.history.push("/summary");
        }
      } else {
        this.props.history.push("/reset-password");
      }
    }
  }

  handleLogin = () => {
    let email = $("#email").val();
    let password = $("#password").val();
    this.setState({ loading: true });
    this.props.login({
      email,
      password,
    });
  };

  verifyOTP = () => {
    let otp = $("#otp").val();
    //console.log(this.props.auth.twoFactor, "this.props.auth");
    this.props.verifyOTP({
      token: this.props.auth.twoFactor.authToken,
      otp: otp,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    let email = $("#email").val();
    // this.setState({ loading: true }, function () {
    Model.default.getAuthMethod(email).then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        this.setState({
          loading: false,
          authMethod: response.data.data,
        });
      }
    });
    // });
  };

  loginByGoogle = () => {
    //console.log("showPassword");
    let email = $("#email").val();
    this.props.googleSignIn(email);
  };

  handleNumChange = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }    
  };

  render() {
    //console.log(this.props.auth, "this.props.auth");
    return (
      <>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>
        <div
          // style={{
          //   backgroundColor: "#0F4B6D",
          //   display: "flex",
          //   height: "100vh",
          // }}
          className="login_container"
        >
          <div style={{ margin: "auto" }}>
            <div
              className="login loginBg"
              style={this.props.auth.twoFactor ? { height: "446px" } : {}}
            >
              {/* <div className="auth_brand">
                  <Link className="navbar-brand" to="/">
                    BrandSek
                  </Link>
                </div> */}
              {/* <div className="">
                  <div className=""> */}
              {/* <h1 className="lead">Login</h1> */}
              {/* <h1></h1> */}
              <br/>
              <span>
                <img style={{width:'120px',display:'flex',margin:'10px auto'}} src="../assets/images/image-gallery/logon_with_text.svg"/>
              </span>
              {/* <div style={{paddingTop:'20px'}}>
                <button
                  type="button"
                  onClick={this.loginByGoogle}
                  className="loginGoogle"
                >
                  <img
                    src={googleImage}
                    class="rounded"
                    style={{
                      width: "22px",
                      height: "22px",
                      margin: "-4px 4px 0 0",
                    }}
                    alt=""
                  ></img>
                  Continue with Google
                </button>
              </div>
              <h5
                style={{
                  width: "96%",
                  textAlign: "center",
                  borderBottom: "1px solid #000",
                  lineHeight: "0.1em",
                  margin: "30px 0 34px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#272b39",
                    padding: "0 10px",
                    fontSize: "14px",
                    color: "#ffffff"
                  }}
                >
                  Or continue with username/email
                </span>
              </h5> */}
              <form className="form-auth-small m-t-20" action="/">
                <div className="form-group">
                  <label htmlFor="email" className="control-label sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control-new round"
                    disabled={this.props.auth.twoFactor}
                    id="email"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group has-feedback">
                  <label htmlFor="password" className="control-label sr-only">
                    Password
                  </label>
                  <input
                    style={{ paddingRight: "30px" }}
                    disabled={this.props.auth.twoFactor}
                    type={this.state.showPassword ? "text" : "password"}
                    className="form-control-new round"
                    id="password"
                    placeholder="Password"
                  />
                  <i
                    className={
                      this.state.showPassword
                        ? "form-control-feedback fa fa-eye-slash"
                        : "form-control-feedback fa fa-eye"
                    }
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  ></i>
                </div>

                {this.props.auth.twoFactor && (
                  <div className="form-group has-feedback">
                    <label htmlFor="password" className="control-label sr-only">
                      Otp
                    </label>
                    <input
                      style={{ paddingRight: "30px" }}
                      type="number"
                      className="form-control-new round"
                      id="otp"
                      // value={this.state.setOtp}
                      maxLength={6}
                      onChange={this.handleNumChange}
                      placeholder="One Time Password"
                    />
                    {/* <i className={this.state.showPassword ? "form-control-feedback fa fa-eye-slash" : "form-control-feedback fa fa-eye"} onClick={() => this.setState({showPassword : !this.state.showPassword})}></i> */}
                  </div>
                )}
                <div className="loginFooter">
                  <p className="remember_me">
                    <label>
                      <input
                        type="checkbox"
                        name="remember_me"
                        id="remember_me"
                      />
                      Remember me on this computer
                    </label>
                  </p>
                  {!this.props.auth.twoFactor && (
                    <p class="submit">
                      {/* <input type="submit" name="commit" value="Login"> */}
                      <button
                        type="button"
                        onClick={this.handleLogin}
                        className="loginButton"
                      >
                        Login
                      </button>
                    </p>
                  )}

                  {this.props.auth.twoFactor && (
                    <p class="submit">
                      {/* <input type="submit" name="commit" value="Login"> */}
                      <button
                        type="button"
                        onClick={this.verifyOTP}
                        className="loginButton"
                      >
                        Login
                      </button>
                    </p>
                  )}
                </div>

                {/* <h5 style={{width: "100%",textAlign: "center",borderBottom: "1px solid #000",lineHeight: "0.1em",margin: "16px 0 20px"}}>
                        <span style={{backgroundColor:"#282b2f",padding:"0 10px"}}>
                          or
                        </span>
                      </h5>
                        <button
                        type="button"
                        onClick={this.loginByGoogle}
                        className="btn btn-primary btn-round btn-block"
                      >
                        Login with Google
                      </button> */}

                {/* <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <Link to="/forgotpassword">Forgot password?</Link>
                        </span>
                      </div> */}
              </form>
              {/* </div>
                </div> */}
            </div>
            <div class="login-help">
              <p>
                Forgot your password?{" "}
                <Link to="/forgotpassword">Click here to reset it</Link>.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
      googleSignIn,
      verifyOTP,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
