import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import Loader from "../resuableComponent/loader";
import {
  fetchWebTech,
  fetchWebTechGraph,
  fetchWebTotal,
  fetchWebTechAction,
} from "../../actions/sub-domain";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  addRemediationData,
  removeRemediationData
} from "../../actions/sub-domain";
import BasicLineColumn from "../common/apexChart/lineChart";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { API_URL } from "../../utils/constant";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


const WebTechnology = () => {
  const location = useLocation();

  let subdomains = useSelector(
    (state) => state?.subDomain?.response?.data?.domains
  );

  let totalData = useSelector((state) => state?.subDomain?.response?.data);

  let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

  let scanDetails = useSelector(
    (state) => state?.subDomain?.garphData?.data
  );

  let totalCount = useSelector(
    (state) => state?.subDomain?.totalCount?.payload?.data
  );

  const [filterData, setfilterData] = useState("all");
  const [searchBy, setSearchBy] = useState("subDomainName");
  subdomains = subdomains?.filter(
    (row) => row?.sslDetails?.[`${row?.subDomainName}`]?.cert_sn
  );
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [name, setName] = useState("");
  const [isModalTwo, setModalTwo] = useState("");
  const [cve, setCve] = useState([]);
  const [category, setCategory] = useState("all");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [checkPath, setCheckPath] = useState('');
  const [addedRemediations, setAddedRemediations] = useState([]);
  const [severity, setSeverity] = useState("");
  const auth = useSelector((state) => state?.auth?.user);
  const [minExploitabilityScore, setMinExploitabilityScore] = useState("");
  const modeValue = new URLSearchParams(location?.search).get('mode');
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );
  const cartDispatch = useDispatch();
  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);


  const myRef = useRef(null);
  const dispatch = useDispatch();
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  // console.log(id,'idddd')

  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then(() => {
              fetchWebTech(id, page, dispatch, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'subdomaintechstacks',
      "organisationId": id,
      "recordId": row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              fetchWebTech(id, page, dispatch, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }


  let distinctFilter = ["all", "Vulnerable", "OK"];

  // First useEffect for fetching total data
  useEffect(() => {
    fetchWebTotal(id, dispatch);
  }, [id, dispatch]);

  // Second useEffect for fetching tech graph
  useEffect(() => {
    fetchWebTechGraph(id, dispatch);
  }, [id, dispatch]);

  // Third useEffect for setting total items
  useEffect(() => {
    setTotalItems(totalData?.totalTechs);
  }, [totalData?.totalTechs]);

  // Fourth useEffect for fetching tech data
  useEffect(() => {
    fetchWebTech(id, page, dispatch, category, filterData, searchBy, search, minExploitabilityScore, severity);
  }, [id, page, dispatch, category, filterData, searchBy, search, minExploitabilityScore, severity]);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const obj = data.map(({ _id, name, uuid }) => ({ subDomainId: _id, name, uuid }));
    if (selectedIds?.length > 0 || selectedIds?.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(obj);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const handleSearchScore = (e) => {
    const inputValue = e.target.value;
    clearTimeout(handleSearchScore.timeoutId);
    handleSearchScore.timeoutId = setTimeout(() => {
      setMinExploitabilityScore(inputValue);
      setPage(1);
    }, 1000);
  };


  const singleClick = (id, name, uuid) => {
    let obj = {
      subDomainId: id,
      name: name,
      uuid: uuid
    };
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds?.some(item => item?.uuid === uuid)) {
        return prevSelectedIds.filter(
          (selectedItem) => selectedItem.uuid !== uuid);
      } else {
        return [...prevSelectedIds, obj];
      }
    });
    if (selectedIds?.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event, name) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let objArray = [];
      let obj = {
        subDomainId: _id,
        name: name,
      };
      objArray.push(obj);
      const data = {
        action: "markAsRemidiated",
        subDomainsAndTechs: selectedIds == 0 ? objArray : selectedIds,
        actionNote: "",
        for: "forever",
        techName: name,
      };
      fetchWebTechAction(
        id,
        page,
        dispatch,
        category,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id, name, uuid) => {
    let objArray = [];
    let obj = {
      subDomainId: _id,
      name: name,
      uuid: uuid
    };
    objArray.push(obj);
    const data = {
      action: "resetAction",
      subDomainsAndTechs: selectedIds == 0 ? objArray : selectedIds,
    };
    fetchWebTechAction(
      id,
      page,
      dispatch,
      category,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    let objArray = [];
    let obj = {
      subDomainId: lookalikeAppId,
      name: name,
    };
    objArray.push(obj);
    const data = {
      action: action,
      subDomainsAndTechs: selectedIds == 0 ? objArray : selectedIds,
      actionNote: actionNote,
      for: forValue,
      techName: name,
    };
    fetchWebTechAction(
      id,
      page,
      dispatch,
      category,
      data
    );
    setModalTwo("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTwo("Form Wizard modal two");
  };

  let currentTableData = [];
  const subdomainsdData = useSelector(
    (state) => state?.subDomain?.response?.data?.technologies
  );

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/webtech_analysis/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Web Technology Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    clearTimeout(handleSearchChange.timeoutId);
    handleSearchChange.timeoutId = setTimeout(() => {
      setSearch(inputValue);
      setPage(1);
    }, 1000);
  };

  const outdatedTechPercent =
    ((totalCount?.totalOutdatedTechs || 0) /
      (totalCount?.totalUsedUniqueTechs + totalCount?.totalOutdatedTechs ||
        0)) *
    100;
  const latesTechPercent =
    ((totalCount?.totalUsedUniqueTechs || 0) /
      (totalCount?.totalUsedUniqueTechs + totalCount?.totalOutdatedTechs ||
        0)) *
    100;
  let mapData = subdomainsdData;


  mapData = mapData;

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const editModal = (row) => {
    setCve(row);
    setModal("Form Wizard modal");
  };


  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (subdomainsdData?.length > 0) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [subdomainsdData]);


  return (
    <div>

      {/* <Header type="security" />
      <SideNav type="security" /> */}

      <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
        <div className='col-sm-2'></div>
        <div className='col-sm-9 gradinet-effect'>
          {scanDetails && (
            <BasicLineColumn
              dates={scanDetails?.dates}
              ipArray={scanDetails?.scanDetails}
              color={["#008ffb", "#00e396"]}
              yAxis="Records Found"
            />
          )}
        </div>
      </div>

      {subdomainsdData ?
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setfilterData("all");
                      setSearchBy("subDomainName");
                      setSearch("");
                      setSelectedIds([]);
                      setSeverity('');
                      setMinExploitabilityScore('');
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setfilterData("all");
                      setSearchBy("subDomainName");
                      setSearch("");
                      setSelectedIds([]);
                      setSeverity('');
                      setMinExploitabilityScore('');
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setfilterData("all");
                      setSearchBy("subDomainName");
                      setSearch("");
                      setSelectedIds([]);
                      setSeverity('');
                      setMinExploitabilityScore('');
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setfilterData("all");
                      setSearchBy("subDomainName");
                      setSearch("");
                      setSelectedIds([]);
                      setSeverity('');
                      setMinExploitabilityScore('');
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-3">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterData}
                    onChange={(e) => {
                      setfilterData(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="Vulnerable">Vulnerable</option>
                    <option value="OK">Ok</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={searchBy}
                    onChange={(e) => {
                      setSearchBy(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="subDomainName">Sub Domain</option>
                    <option value="techStack">Tech Stack</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <div
                    data-test="datatable-input"
                    class="mdb-datatable-filter flex-row"
                  >
                    <input
                      class="form-control form-control-sm ml-0 my-1"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div
                    data-test="datatable-input"
                    class="mdb-datatable-filter flex-row"
                  >
                  </div>
                  {modeValue == "report-mode" && <>
                    <div className="col-md-1 text-left mt-1">
                      {currentTableData?.length === 0 ? null : (
                        <span>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              downloadFile();
                              // exportToCSV(downloadCsvData, "Sub-Domain Details");
                            }}
                            href="/add-report"
                            style={{ color: "black", fontWeight: "bold" }}
                            className="btn btn-primary mr-1"
                          >
                            <i
                              className="fa fa-file-excel-o"
                              style={{ color: "black" }}
                            ></i>
                          </a>
                        </span>
                      )}
                    </div>
                  </>}
                </div>

              </div>
              <div className="row_boxes row_boxes_table">
                <div className="table_box table_box_full">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>Status</th>
                        <th>Sub Domain</th>
                        <th>Tech Stack</th>
                        <th>Current Version</th>
                        <th>Latest Version</th>
                        <th>Cves Detail</th>
                        <th>Exploitable</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((res, index) => {
                        return (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() =>
                                      singleClick(
                                        res?._id,
                                        res?.name,
                                        res?.uuid
                                      )
                                    }
                                    checked={selectedIds?.some(
                                      (item) => item?.uuid == res?.uuid
                                    )}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              {res?.hasVulnerabilities ? (
                                <span className="badge badge-danger">
                                  Vulnerable
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  Ok
                                </span>
                              )}
                            </td>
                            <td id="info">{res?.subDomainName}</td>
                            <td>{res?.name}</td>
                            <td>{res?.version}</td>
                            <td>{res?.latestVersion}</td>
                            <td
                              onClick={() =>
                                Object.keys(res?.exploits || {}).length !== 0 &&
                                editModal(res)
                              }
                              style={{
                                cursor:
                                  Object.keys(res?.exploits || {}).length !== 0
                                    ? "pointer"
                                    : "auto",
                              }}
                            >
                              {Object.keys(res?.exploits || {}).length !== 0
                                ? "Vulnerable (Detail)"
                                : "N/A"}
                            </td>
                            <td>
                              {res?.exploits &&
                                Object.values(res?.exploits).some(
                                  (exploit) => exploit?.length > 0
                                )
                                ? "Yes"
                                : "No"}
                            </td>
                            {res?.remediationPending == true ? <td>Retest in progress</td> : <>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(
                                            res?._id,
                                            event.target.value,
                                            res?.name
                                          );
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setName(res?.name);
                                            setLookalikeAppId([res?._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setName(res?.name);
                                            setLookalikeAppId([res?._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                            </>
                            }
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() =>
                                      Unsnoozed(
                                        res?._id,
                                        res?.name,
                                        res?.uuid
                                      )
                                    }
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {checkPath == '?mode=assessment-mode' && clientType == "Admin" ?
                              <td>
                                {res?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...res,
                                      remediationType: "Sub Domain",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(res?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
            style={{
              height: "60%"
            }}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Cve Details
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModal("")}
                    style={{
                      border: 'transparent',
                      background: 'transparent',
                      color: '#fff',
                      fontSize: '18px',
                      marginBottom: '-10px'
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>

                </div>
                <div className="modal-body pricing_page">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                          <div className="col-lg-12 col-md-12">
                            <table className="table" style={{ borderCollapse: 'collapse', border: '1px solid #ccc' }}>
                              <thead>
                                <tr>
                                  <th style={{ border: '1px solid #ccc', padding: '8px' }}>CVE ID</th>
                                  <th style={{ border: '1px solid #ccc', padding: '8px' }}>Known Exploit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cve?.exploits &&
                                  Object.entries(cve?.exploits).map(([cveId, links], index) => (
                                    <tr key={index} style={{ border: '1px solid #ccc' }}>
                                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                        <strong>
                                          <Link
                                            // to={`/cve/${cveId}`}
                                            className="p-2 rounded-md bg-slate-200"
                                          >
                                            {cveId}
                                          </Link>
                                        </strong>
                                      </td>
                                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                        <ul className="m-0">
                                          {links.map((link, linkIndex) => (
                                            <li className="m-0" key={linkIndex}>
                                              <a
                                                href={link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                              >
                                                {link ? link : "N/A"}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          className={`modal fade Form-Wizard-modal ${isModalTwo === "Form Wizard modal two" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalTwo("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModalTwo("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div> :
        <Loader />
      }

    </div>
  );
}

export default WebTechnology;