import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  FETCH_MOBILE_APP,
  FETCH_FAKE_SOCIAL_MEDIA,
  FETCH_DARK_WEB_MENTION,
  FETCH_WEB_DEFACEMENT,
  FETCH_MOBILE__GRAPH,
  FETCH_FAKE_SOCIAL_MEDIA_TOTAL,
  SUSPICIOUS_CLOUD_GRAPH,
  FETCH_FAKE_SOCIAL_MEDIA_EXCEL
} from ".";
import { API_URL } from "../utils/constant";

export const fetchMobileApp = (props, dispatch, page, type,category) => {
  if (props) {
    axios
      .get(
        `${API_URL}/get-appsv2/${props}/delta?page=${page}&type=${type}&category=${category}`,
        {
          params: {
            customerId: props,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        dispatch({
          type: FETCH_MOBILE_APP,
          mobileData: res.data,
        });
      });
  }
};

export const fetchMobileRemidiatedFalseTrue = (props, dispatch, data ,page ,category) => {
  if (props) {
    axios
      .post(`${API_URL}/get-appsv2/${props}/action?page=${page}&category=${category}`, data)
      .then((res) => {
        dispatch({
          type: FETCH_MOBILE_APP,
          mobileData: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchScanGraph = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/get-appsv2/${props}/graph`).then((res) => {
      //console.log(res);
      dispatch({
        type: FETCH_MOBILE__GRAPH,
        mobileData: res.data,
      });
    });
  }
};

export const fetchDarkWebMention = (props, dispatch) => {
  axios
    .get(`${API_URL}/getDarkWebMentions`, {
      params: {
        orgId: props,
      },
    })
    .then((res) => {
      //console.log(res);
      dispatch({
        type: FETCH_DARK_WEB_MENTION,
        data: res.data.data,
      });
    });
};

export const fetchFakeSocialMedia = (
  props,
  dispatch,
  page,
  category,
  platform,
  verified
) => {
  if (props) {
    axios
      .get(
        `${API_URL}/getFakeSocialMediav2/${props}/delta?page=${page}&category=${category}&platform=${platform}&status=${verified}`,
        {
          params: {
            orgId: props,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        dispatch({
          type: "FETCH_FAKE_SOCIAL_MEDIA",
          mobileData: res.data,
        });
      });
  }
};

export const fetchSocailRemidiatedFalseTrue = (props, dispatch, data ,page ,category ,type) => {
  if (props) {
    axios
      .post(`${API_URL}/getFakeSocialMediav2/${props}/action?page=${page}&category=${category}&type=${type}`, data)
      .then((res) => {
        dispatch({
          type: "FETCH_FAKE_SOCIAL_MEDIA",
          mobileData: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchFakeSocialMediaTotal = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/getFakeSocialMediav2/${props}/totals`).then((res) => {
      dispatch({
        type: FETCH_FAKE_SOCIAL_MEDIA_TOTAL,
        fakeSocialCount: res.data,
      });
    });
  }
};


export const fetchFakeSocialMediaGraph = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/getFakeSocialMediav2/${props}/graph`)
      .then((res) => {
        //console.log(res);
        dispatch({
          type: FETCH_FAKE_SOCIAL_MEDIA_EXCEL,
          action: res.data,
        });
      });
  }
};

export const getAppById = (props, dispatch) => {
  //console.log(props);
  axios
    .get(`${API_URL}/getApp`, {
      params: {
        customerId: props.customerId,
        appId: props.appId,
      },
    })
    .then((res) => {
      dispatch({
        type: "FETCHED_MOBILE_DATA",
        fetchedMobileData: res.data,
      });

      toastr.success("Success", "App fetched Successfully");
    })
    .catch((err) => {
      toastr.err("Error", "App Had an issue while fetching!");
    });
};

export const markAsSuspicious = (
  _id,
  _isSuspicious,
  _isBlacklist,
  _isLinkDown,
  _action,
  _orgId,
  dispatch
) => {
  axios
    .patch(`${API_URL}/updateApp`, {
      id: _id,
      isSuspicious: _isSuspicious,
      isBlacklist: _isBlacklist,
      isLinkDown: _isLinkDown,
      organisationId: _orgId,
    })
    .then((res) => {
      if (!res.data.error) {
        // toastr.success("SUCCESS", "Marked as Suspicious");
        if (_action == "Suspicious") {
          toastr.success(
            "SUCCESS",
            `${
              _isSuspicious ? "Marked as Suspicious" : "Removed from Suspicious"
            }`
          );
        } else if (_action == "Blacklist") {
          toastr.success(
            "SUCCESS",
            `${_isBlacklist ? "Marked as Blacklist" : "Removed from Blacklist"}`
          );
        } else {
          toastr.success(
            "SUCCESS",
            `${_isLinkDown ? "Marked as Linkdown" : "Removed from Linkdown"}`
          );
        }
        dispatch({
          type: FETCH_MOBILE_APP,
          mobileData: res.data,
        });
      } else {
        toastr.error("ERROR", "SOMETHING WENT WRONG!");
      }
    })
    .catch((err) => {
      toastr.error("ERROR", "SOMETHING WENT WRONG!");
    });
};

export const getOpenCloudData = (keyword, orgId, dispatch) => {
  if (keyword && orgId) {
    axios
      .get(`${API_URL}/cloud-bucket`, {
        params: {
          orgId: orgId,
        },
      })
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: "SET_OPEN_CLOUD",
          data: res.data.data,
        });
      });
  }
};

export const getAllCloudRepo = (keyword, orgId, dispatch) => {
  if (keyword && orgId) {
    axios
      .get(`${API_URL}/open-cloud`, {
        params: {
          keyword: keyword,
          orgId: orgId,
        },
      })
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: "SET_OPEN_CLOUD_ALL",
          data: res.data.data,
        });
      });
  }
};

export const updateStatus = (
  _id,
  _isSuspicious,
  _isBlacklisted,
  _keyword,
  dispatch
) => {
  axios
    .patch(`${API_URL}/open-cloud`, {
      id: _id,
      isSuspicious: _isSuspicious,
      isBlacklisted: _isBlacklisted,
      keyword: _keyword,
    })
    .then((res) => {
      if (!res.data.error) {
        toastr.success(
          "SUCCESS",
          `${_isSuspicious ? "Marked as Suspicious" : "Marked as Blacklisted"}`
        );
        if (_isBlacklisted) {
          dispatch({
            type: "SET_OPEN_CLOUD",
            data: res.data.data,
          });
        }
      } else {
        toastr.error("ERROR", "SOMETHING WENT WRONG!");
      }
    })
    .catch((err) => {
      toastr.error("ERROR", "SOMETHING WENT WRONG!");
    });
};

export const getSuspicousCloudData = (orgId, dispatch, page, category,type) => {
  //console.log("getSuspicousCloudData");
  if (orgId) {
    axios
      .get(
        `${API_URL}/cloud-bucket-details/${orgId}/delta?page=${page}&category=${category}&type=${type}`,
        {
          params: {
            orgId: orgId,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: "SET_SUSPICIOUS_CLOUD",
          data: res.data,
        });
      });
  }
};

export const getSuspicousCloudAction = (
  orgId,
  dispatch,
  page,
  category,
  data
) => {
  //console.log("getSuspicousCloudData");
  if (orgId) {
    axios
      .post(
        `${API_URL}/cloud-bucket-details/${orgId}/action?page=${page}&limit=10&category=${category}`,
        data
      )
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: "SET_SUSPICIOUS_CLOUD",
          data: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const getSuspicousCloudGraph = (orgId, dispatch) => {
  //console.log("getSuspicousCloudData");
  if (orgId) {
    axios.get(`${API_URL}/cloud-bucket-details/${orgId}/graph`).then((res) => {
      dispatch({
        type: SUSPICIOUS_CLOUD_GRAPH,
        data: res.data,
      });
    });
  }
};

export const markSuspicious = (
  _id,
  _isSuspicious,
  _isBlacklist,
  _isLinkDown,
  _type,
  _action,
  _orgId,
  dispatch
) => {
  let url = "";

  if (_type == "sourceCode") url = `${API_URL}/updateSourceCode`;
  else if (_type == "darkWeb") url = `${API_URL}/updateDarkWeb`;
  else if (_type == "socialMedia") url = `${API_URL}/updateFakeSocial`;

  axios
    .patch(url, {
      id: _id,
      isSuspicious: _isSuspicious,
      isBlacklist: _isBlacklist,
      isLinkDown: _isLinkDown,
      organisationId: _orgId,
    })
    .then((res) => {
      if (!res.data.error) {
        if (_action == "Suspicious") {
          toastr.success(
            "SUCCESS",
            `${
              _isSuspicious ? "Marked as Suspicious" : "Removed from Suspicious"
            }`
          );
        } else if (_action == "Blacklist") {
          toastr.success(
            "SUCCESS",
            `${_isBlacklist ? "Marked as Blacklist" : "Removed from Blacklist"}`
          );
        } else {
          toastr.success(
            "SUCCESS",
            `${_isLinkDown ? "Marked as Linkdown" : "Removed from Linkdown"}`
          );
        }
        if (_type == "sourceCode") {
          dispatch({
            type: "SET_LEAKED_CODE",
            data: res.data,
          });
        } else if (_type == "darkWeb") {
          dispatch({
            type: FETCH_DARK_WEB_MENTION,
            data: res.data.data,
          });
        } else {
          dispatch({
            type: FETCH_FAKE_SOCIAL_MEDIA,
            mobileData: res.data,
          });
        }
      } else {
        toastr.error("ERROR", "SOMETHING WENT WRONG!");
      }
    })
    .catch((err) => {
      //console.log(err);
      toastr.error("ERROR", "SOMETHING WENT WRONG!");
    });
};

export const webDefacement = (orgId, dispatch) => {
  if (orgId) {
    axios
      .get(
        `https://api.brandsek.com/api/report/website_defacement?organisationId=${orgId}&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFkbWluQGJyYW5kc2VrLmNvbSIsInVzZXJJZCI6IjYxZWE2YWUwM2E2NjhjM2UzYTk4NmY2ZCIsImlhdCI6MTY2MjMwNDA3NywiZXhwIjoxNjYyMzExMjc3fQ.24guuW48l9hLsHmwYaLsBuLJW2gc3kpIobTIWPLxMkk`
      )
      .then((res) => {
        //console.log(res?.data?.data?.data?.website_defacement_report?.[0]?.links,'webDefacement');
        dispatch({
          type: FETCH_WEB_DEFACEMENT,
          data: res?.data?.data?.data?.website_defacement_report?.[0]?.links,
        });
      });
  }
};
