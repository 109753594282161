import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFakeSocialMedia, fetchSocailRemidiatedFalseTrue, fetchFakeSocialMediaGraph, fetchFakeSocialMediaTotal } from "../../actions/fakeMobileApp";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import Loader from "../resuableComponent/loader";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import BasicBar from "../common/apexChart/basicBar";
import rightImg from "../../assets/images/right-clock.png";
import leftImg from "../../assets/images/left-clock.png";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";



const SocialMediaProfile = () => {
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  let verifyStatus;
  const [filterData, setfilterData] = useState("");
  const [statusFilterVal, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [PageSize, setPerPage] = useState(10);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [category, setCategory] = useState("all");
  const [type, settype] = useState("all");
  const [status, setStatus] = useState("all");
  const [platform, setPlatform] = useState("all");
  const params = new URLSearchParams(location?.search);
  const [checkPath, setCheckPath] = useState('');
  const modeValue = params.get('mode');
  const modeValueCheck = new URLSearchParams(location?.search).get('mode');
  const auth = useSelector((state) => state?.auth?.user);


  const state = useSelector(
    (state) => state?.fakeMobileApp?.fakeSocialMedia?.data
  );
  const total = useSelector(
    (state) => state?.fakeMobileApp?.faketotal?.data
  );
  const scanCompleted = useSelector(
    (state) => state?.fakeMobileApp?.scanCompleted
  );
  const graphData = useSelector(
    (state) => state?.fakeMobileApp?.socialMediaGraph
  );
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );

  const cartDispatch = useDispatch();
  let remediationList = useSelector(
    (state) => state?.auth?.remediationListAll?.data?.data
  );
  const [addedRemediations, setAddedRemediations] = useState([]);

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then(() => {
              fetchFakeSocialMedia(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'delta_social_media_profiles',
      "organisationId": id,
      "recordId": row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              fetchFakeSocialMedia(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  let objArray = {};
  for (let count = 0; count < state?.length; count++) {
    let createdAt = state[count].createdAt.split("T")[0];
    if (objArray[createdAt]) {
      objArray[createdAt].push(state[count]);
    } else {
      objArray[createdAt] = [state[count]];
    }
  }
  //console.log(objArray, 'createdAt')

  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const scanDetails = useSelector(
    (state) => state?.fakeMobileApp?.fakeSocialMedia?.scanDetailArray
  );

  const facebookMedia = '';
  const linkedinMedia = '';
  const twitterMedia = '';
  const instagramMedia = '';

  let facebook = [],
    linkedin = [],
    twitter = [],
    instagram = [];
  let dateKey = [];
  Object.keys(objArray || {}).map((key) => {
    dateKey.push(key);
    let value = objArray[key];
    let facebookMedia = value?.filter((val) => val.source == "facebook");
    let linkedinMedia = value?.filter((val) => val.source == "Linkedin");
    let twitterMedia = value?.filter((val) => val.source == "twitter");
    let instagramMedia = value?.filter((val) => val.source == "instagram");

    facebook.push(facebookMedia?.length || 0);
    linkedin.push(linkedinMedia?.length || 0);
    twitter.push(twitterMedia?.length || 0);
    instagram.push(instagramMedia?.length || 0);
  });

  useEffect(() => {
    fetchFakeSocialMedia(id, dispatch, page, category, platform, status);
  }, [id, dispatch, page, category, platform, status]);

  useEffect(() => {
    fetchFakeSocialMediaTotal(id, dispatch)
  }, [id, dispatch])

  useEffect(() => {
    fetchFakeSocialMediaGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(state?.totalProfiles);
  }, [state?.totalProfiles]);


  let mapData = state?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/getFakeSocialMediav2/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Social Media Page.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };


  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [state]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        fakeSocialMediaIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      fakeSocialMediaIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      fakeSocialMediaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const [activeBoxes, setActiveBoxes] = useState({
    box1: false,
    box2: false,
    box3: false,
    box4: false,
    box5: false,
  });

  const handleBoxClick = (boxId) => {
    if (modeValue === 'report-mode') {
      setActiveBoxes(prevState => ({
        ...prevState,
        [boxId]: !prevState[boxId]
      }));
    } else {
      setActiveBoxes({});
    }
  };

  useEffect(() => {
    handleBoxClick('');
  }, [modeValue]);


  return (
    <div>
      {/* <Header type="brand" />
      <SideNav type="brand" /> */}

      {state ? (
        <div className={containerClass}>
          <div className="tab_facebook">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-9 mb-5">
                <div class="row_boxes">
                  <div className={`box box-active`}
                    onClick={() => handleBoxClick('box1')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      {/* <div class="box_6">
                        <h4>{facebookMedia.length}</h4>
                        <p>Previous</p>
                      </div> */}
                      <img
                        src="/assets/images/fb.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="box_7">
                        <h4>{total?.totalFacebookProfiles}</h4>
                        <p>Total Count</p>
                      </div>
                    </div>
                    <a href="">
                      <span>
                        <img
                          src="/assets/images/plus.png"
                          class="img-fluid"
                          alt=""
                        />
                      </span>
                      <p>add Scan process</p>
                    </a>
                  </div>
                  <div className={`box ${activeBoxes.box2 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box2')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      {/* <div class="box_6">
                        <h4>{instagramMedia.length}</h4>
                        <p>Previous</p>
                      </div> */}
                      <img
                        src="/assets/images/instagram_icon.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="box_7">
                        <h4>{total?.totalInstagramProfiles}</h4>
                        <p>Total Count</p>
                      </div>
                    </div>
                    <a href="">
                      <span>
                        <img
                          src="/assets/images/plus.png"
                          class="img-fluid"
                          alt=""
                        />
                      </span>
                      <p>add Scan process</p>
                    </a>
                  </div>
                  <div className={`box ${activeBoxes.box3 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box3')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      {/* <div class="box_6">
                        <h4>{linkedinMedia.length}</h4>
                        <p>Previous</p>
                      </div> */}
                      <img
                        src="/assets/images/linkedin_icon.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="box_7">
                        <h4>{total?.totalLiknedInProfiles}</h4>
                        <p>Total Count</p>
                      </div>
                    </div>
                    <a href="">
                      <span>
                        <img
                          src="/assets/images/plus.png"
                          class="img-fluid"
                          alt=""
                        />
                      </span>
                      <p>add Scan process</p>
                    </a>
                  </div>
                  <div className={`box ${activeBoxes.box4 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box4')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      {/* <div class="box_6">
                        <h4>{twitterMedia.length}</h4>
                        <p>Previous</p>
                      </div> */}
                      <img
                        src="/assets/images/twitter_icon.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="box_7">
                        <h4>{total?.totalTwitterProfiles}</h4>
                        <p>Total Count</p>
                      </div>
                    </div>
                    <a href="">
                      <span>
                        <img
                          src="/assets/images/plus.png"
                          class="img-fluid"
                          alt=""
                        />
                      </span>
                      <p>add Scan process</p>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-9 mt-5 mb-5">
                <div class="row_boxes row_boxes_graph">
                  <div className={`box p-1 box-active`}
                    onClick={() => handleBoxClick('box1')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      <img
                        src="/assets/images/fb.png"
                        class="img-fluid imgGraphIcon"
                        alt=""
                      />
                      <div className="d-flex justify-content-center align-items-end">
                        <img src={rightImg} className="img-fluid position-relative" />
                        <BasicBar date={graphData?.dates} data={graphData?.scanDetails?.facebook} />
                        <img src={leftImg} className="img-fluid position-relative" />
                      </div>
                    </div>
                  </div>
                  <div className={`box p-1 ${activeBoxes.box2 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box2')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      <img
                        src="/assets/images/instagram_icon.png"
                        class="img-fluid imgGraphIcon"
                        alt=""
                      />
                      <div className="d-flex justify-content-center align-items-end">
                        <img src={rightImg} className="img-fluid position-relative" />
                        {/* <BasicBar /> */}
                        <BasicBar date={graphData?.dates} data={graphData?.scanDetails?.instagram} />

                        <img src={leftImg} className="img-fluid position-relative" />
                      </div>
                    </div>
                  </div>
                  <div className={`box p-1 ${activeBoxes.box3 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box3')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      <img
                        src="/assets/images/linkedin_icon.png"
                        class="img-fluid imgGraphIcon"
                        alt=""
                      />
                      <div className="d-flex justify-content-center align-items-end">
                        <img src={rightImg} className="img-fluid position-relative" />
                        {/* <BasicBar /> */}
                        <BasicBar date={graphData?.dates} data={graphData?.scanDetails?.linkdin} />

                        <img src={leftImg} className="img-fluid position-relative" />
                      </div>
                    </div>
                  </div>
                  <div className={`box p-1 ${activeBoxes.box4 ? 'box-active' : ''}`}
                    onClick={() => handleBoxClick('box4')}>
                    <span class="profile">PROFILES</span>
                    <div class="box_up">
                      <img
                        src="/assets/images/twitter_icon.png"
                        class="img-fluid imgGraphIcon"
                        alt=""
                      />
                      <div className="d-flex justify-content-center align-items-end">
                        <img src={rightImg} className="img-fluid position-relative" />
                        {/* <BasicBar /> */}
                        <BasicBar date={graphData?.dates} data={graphData?.scanDetails?.twitter} />

                        <img src={leftImg} className="img-fluid position-relative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                {/* <div className="gradinet-effect w-100"> 
               {scanDetails && <SubscriberChart dates={scanDetails?.dates} ipArray={ipArray} color={["#6574cd"]} yAxis="Similar Domains found" />} 
               {scanDetails && <BasicLineColumn dates={scanDetails?.dates} ipArray={ipArray} color={["#6574cd"]} yAxis="Similar Domains found" />} 
               {scanDetails && <SubscriberChart dates={dateKey} facebook={facebook} linkedin={linkedin} twitter={twitter} instagram={instagram} />} 
               </div> */}
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      settype("all");
                      setPage(1);
                      setStatus("all");
                      setPlatform("all");
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      settype("all");
                      setPage(1);
                      setStatus("all");
                      setPlatform("all");
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      settype("all");
                      setPage(1);
                      setStatus("all");
                      setPlatform("all");
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      settype("all");
                      setPage(1);
                      setStatus("all");
                      setPlatform("all");
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-12">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={platform}
                    onChange={(e) => { setPlatform(e.target.value); setPage(1) }}
                  >
                    <option value="all">All</option>
                    <option value="facebook">Facebook</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="twitter">Twitter</option>
                    <option value="instagram">Instagram</option>
                  </select>
                </div>
                <div className="col-lg-4 col-md-12">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={status}
                    onChange={(e) => { setStatus(e.target.value); setPage(1) }}
                  >
                    <option value="all">All</option>
                    <option value="verified">Verified</option>
                    <option value="unverified">Unverified</option>
                    <option value="linkdown">Takedown</option>
                  </select>
                </div>
                {modeValueCheck == "report-mode" && <>
                  <div className="col-md-4 col-sm-12 text-right hidden-xs">
                    {client?.subscriptionType != "trial" && currentTableData?.length !== 0 && (
                      <span>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          style={{
                            float: "right",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          href="/add-report"
                          className="btn btn-primary mr-1"
                        >
                          <i
                            className="fa fa-file-excel-o"
                            style={{ color: "black" }}
                          ></i>
                        </a>
                      </span>
                    )}
                  </div></>}
              </div>
              <div className="row_boxes row_boxes_table">
                <div
                  className={`table_box ${activeBoxes.box5 ? 'table-box-active' : ''}`}
                  onClick={() => handleBoxClick('box5')}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>#No</th>
                        <th>Status</th>
                        <th>Platform</th>
                        <th>Page URL</th>
                        <th>Last Updated at</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((res, index) => {
                        let source =
                          res?.source || res?.socialMediaPlatform || "NA";
                        const pageCount = (page - 1) * 10 + 1;
                        return (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(res?._id)}
                                    checked={selectedIds?.includes(res?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              <span>{pageCount + index}</span>
                            </td>
                            <td>
                              {" "}
                              <span
                                className={
                                  res?.isSuspicious
                                    ? "badge badge-success"
                                    : "badge badge-danger"
                                }
                              >
                                {res?.isSuspicious
                                  ? "Verified"
                                  : "Unverified"}
                              </span>
                              {res?.isLinkDown && (
                                <span className="badge badge-warning">
                                  Take down
                                </span>
                              )}
                            </td>
                            <td>
                              {source.charAt(0).toUpperCase() +
                                source.slice(1)}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <a
                                href={`${res?.link || res?.fakePageUrl}`}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                {res?.link || res?.fakePageUrl}
                              </a>
                            </td>
                            <td>
                              {res?.updatedAt
                                ? moment(res?.updatedAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                                : res?.createdAt
                                  ? moment(res?.createdAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                  : "NA"}
                            </td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(res?._id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(res?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {checkPath == '?mode=assessment-mode' && clientType == "Admin" ?
                              <td>
                                {res?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...res,
                                      remediationType: "Social Media Profile",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(res?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">Snooze for</label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              className="form-control"
                              value={actionNote}
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SocialMediaProfile;
