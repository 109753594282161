import { INVENTORY_FETCHED,INVENTORY_FETCHED_GRAPH,ASSETS_INTERNAL_PAGES,ALL_PORT,
  ALL_COUNTRY,
  ALL_CLOUD, INVENTORY_FETCHED_DISCOVERY, SINGLE_INVENTORY_FETCHED, FETCHING_SINGLE_INVENTORY, ALL_INVENTORY_FETCHED, SINGLE_INVENTORY_DATA_FETCHED } from "../actions/index";

export default function (
  state = { loading: true, assetsInnerPage:[], loaded: true, ipdiscovery:[], ipblackgraph:[], response: null, list: null, singleInventory: null, allInventory: [], singleInventoryData: null, scanCompleted: true },
  action
) {
  switch (action.type) {
    case ALL_CLOUD:{
      return {
        ...state,
        allcloud:action.payload,
      }
    }
    case ALL_COUNTRY:
      return{
        ...state,
        allcountry:action.payload,
      }
    case ALL_PORT:
      return{
        ...state,
        allport:action.payload,
      }
    case ASSETS_INTERNAL_PAGES:
      return {
        ...state,
        assetsInnerPage:action.payload,
        loaded: !action.payload ? true : false,
      } 
    case INVENTORY_FETCHED_DISCOVERY:
      return {
        ...state,
        ipdiscovery:action.payload,
        loaded: !action.payload ? true : false,
      }
    case INVENTORY_FETCHED:
      return {
        ...state,
        response: action.payload ? action.payload : [],
        list: action.payload && !action.payload.error ? action.payload.data : [],
        loading: false,
        loaded: !action.payload ? true : false,
        scanCompleted: action?.payload?.scanCompleted
      };
    case FETCHING_SINGLE_INVENTORY:
        return {
          ...state,
          singleInventory: null,
          loading: true,
        };
        case INVENTORY_FETCHED_GRAPH:
        return {
          ...state,
          ipblackgraph: action.data
        };
    case SINGLE_INVENTORY_FETCHED:
      return {
        ...state,
        singleInventory: action.singleInventory,
        loading: false,
      };
    case ALL_INVENTORY_FETCHED:
      return {
        ...state,
        allInventory: action.payload,
        loading: false,
      };
    case SINGLE_INVENTORY_DATA_FETCHED:
      return {
        ...state,
        singleInventoryData: action.singleInventory,
        loading: false,
      };
    default:
      return state;
  }
}
