import React, { Component, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import DNS from "../../assets/images/DNS_Dashboard.svg";
import Known from "../../assets/images/VULNERAB_Dashboard.svg";
import SSL from "../../assets/images/SSLCERT_Dashboard.svg";
import Source from "../../assets/images/LEAKAGE_Dashboard.svg";
import Assets from "../../assets/images/ASSETSDISCOV_Dashboard.svg";
import Stolen from "../../assets/images/STOLENCRED_Dashboard.svg";
import Black from "../../assets/images/BLACKIP_Dashboard.svg";
import FakeMob from "../../assets/images/FAKESME_Dashboard.svg";
import FakeApp from "../../assets/images/FAKEAPP_Dashboard.svg";
import SUBDOM from "../../assets/images/SUBDOM_Dashboard.svg";
import Host from "../../assets/images/HOSTSDISC_Dashboard.svg";
import HostLocation from "../../assets/images/HOSTLOC_Dashboard.svg";
import CloudProviderSvg from "../../assets/images/CLOUDPROV_Dashboard.svg";
import OpenPorts from "../../assets/images/OPENPORTS_Dashboard.svg";
import OpertingSystem from "../../assets/images/OS_Dashboard.svg";
import networkService from "../../assets/images/NETWSERV_Dashboard.svg";

const Sidenav = (props) => {
  // console.log(props, 'Sidenav')
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const modeValue = params.get('mode');
  const [pathname, setPathname] = useState(location.pathname);
  const [hash, setHash] = useState(location.hash.replace('#', ''));
  const [mode, setMode] = useState('assessment-mode');
  // useEffect(() => {
  //   setPathname(location.pathname);
  //   setHash(location.hash.replace('#', ''));
  // }, [location.pathname, location.hash]);
  

  // useEffect(() => {
  //   const storedMode = localStorage.getItem('mode');
  //   if (storedMode) {
  //     setMode(storedMode);
  //   }
  // }, []);


  useEffect(() => {
    setPathname(location.pathname);
    setHash(location.hash.replace('#', ''));

    // Update the URL to include mode in the hash
    if (mode) {
      const newHash = `${location.hash.replace('#', '')}?mode=${mode}`;
      window.history.replaceState(null, '', `${pathname}#${newHash}`);
    }
  }, [location.pathname, location.hash, mode]);

  useEffect(() => {
    const storedMode = localStorage.getItem('mode');
    if (storedMode) {
      setMode(storedMode);
    }
  }, []);

  const overlayToggle = {
    true: "text_img_sidebar active_sidebar_check",
    false: "text_img_sidebar"
  }

  console.log('modeValue', modeValue)

  let selectedRoute = "";
  if (
    props?.type?.indexOf("security") !== -1 ||
    props.type === "/domains" ||
    props.type === "/known-vulnerabilities" ||
    props.type === "/ssl-certificates" ||
    props.type === "/web-technology"
  )
    selectedRoute = "security";
  else if (props?.type?.indexOf("assets") !== -1)
    selectedRoute = "assets";
  else if (props?.type?.indexOf("brand") !== -1 || props.type === "/mobile-apps" || props.type === "/look-alike" || props.type === "/social-media")
    selectedRoute = "brand";
  else if (props?.type?.indexOf("services") !== -1 || props.type === "/services")
    selectedRoute = "services";
  if (modeValue !== 'settings-mode') {
    if (selectedRoute && selectedRoute === "security") {
      return (
        <div class="side_bar">
          <ul>
            <li>
              <a href="javascript:void(0)" class="menu_btnside">
                <img src="/assets/images/image-gallery/MenuIcon.png" class="img-fluid" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_1">
                <span>Security Healthcheck</span>
              </a>
            </li>
            <li>
              <Link to="/security/domains" className={overlayToggle[pathname === "/security/domains" || pathname == "/security"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={DNS}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Domain & DNS Health Check</span>
              </Link>
            </li>
            <li>
              <Link to="/security/sub-domain" className={overlayToggle[pathname === "/security/sub-domain"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={SUBDOM}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Subdomain</span>
              </Link>
            </li>
            <li>
              <Link to="/security/known-vulnerabilities" className={overlayToggle[pathname === "/security/known-vulnerabilities"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={Known}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Known Vulnerabilities</span>
              </Link>
            </li>
            <li>
              <Link to="/security/ssl-certificates" className={overlayToggle[pathname === "/security/ssl-certificates"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={SSL}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>SSL Certificates</span>
              </Link>
            </li>
            <li>
              <Link to="/security/web-technology" className={overlayToggle[pathname === "/security/web-technology"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/image-gallery/web_technologies.svg"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Web Technologies</span>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_2">
                <span>Security Healthcheck</span>
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (selectedRoute && selectedRoute === "brand") {
      return (
        <div class="side_bar">
          <ul>
            <li>
              <a href="javascript:void(0)" class="menu_btnside">
                <img src="/assets/images/image-gallery/MenuIcon.png" class="img-fluid" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_1">
                <span>Brand Security</span>
              </a>
            </li>
            <li>
              <Link to="/brand/mobile-apps" className={overlayToggle[pathname === "/brand/mobile-apps" || pathname === "/brand"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={FakeApp}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Mobile Application</span>
              </Link>
            </li>
            <li>
              <Link to="/brand/look-alike" className={overlayToggle[pathname === "/brand/look-alike"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/image-gallery/Icon_1.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Look - Alike</span>
              </Link>
            </li>
            <li>
              <Link to="/brand/social-media" className={overlayToggle[pathname === "/brand/social-media"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={FakeMob}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Social Media Profiles</span>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_2">
                <span>Brand Security</span>
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (selectedRoute && selectedRoute === "services") {
      return (
        <div class="side_bar">
          <ul>
            {<li>
              <a href="javascript:void(0)" class="menu_btnside">
                <img src="/assets/images/valkyrie_service.svg" class="img-fluid" />
              </a>
            </li>}
            <li>
              <a href="javascript:void(0)" class="security_check_1">
                <span>Valkyrie Services</span>
              </a>
            </li>
            <li>
              <Link to="/services#identify" className={overlayToggle[hash === "identify"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Identify_Valkyrie.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Identify</span>
              </Link>
            </li>
            <li>
              <Link to="/services#protect" className={overlayToggle[hash === "protect"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Protect_Valkyrie.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Protect</span>
              </Link>
            </li>
            <li>
              <Link to="/services#detect" className={overlayToggle[hash === "detect"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Detect_Valkyrie.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Detect</span>
              </Link>
            </li>
            <li>
              <Link to="/services#respond" className={overlayToggle[hash === "respond"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Respond_Valkyrie.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Respond</span>
              </Link>
            </li>
            <li>
              <Link to="/services#recover" className={overlayToggle[hash === "recover"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Recover_Valkyrie.svg"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Recover</span>
              </Link>
            </li>
            <li>
              <Link to="/services#custom" className={overlayToggle[hash === "custom"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/Custom_Valkyrie.svg"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Custom Services</span>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_2">
                {<span>Valkyrie Services</span>}
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (selectedRoute && selectedRoute === "assets") {
      return (
        <div class="side_bar">
          <ul>
            <li>
              <a href="javascript:void(0)" class="menu_btnside">
                <img src="/assets/images/Assets_IconMain_.svg" class="img-fluid" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_1">
                <span>Asset Monitoring</span>
              </a>
            </li>
            <li>
              <Link to="/assets/#hostsDiscovered" className={overlayToggle[hash === "hostsDiscovered"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={Host}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Hosts Discovered</span>
              </Link>
            </li>
            <li>
              <Link to="/assets/#hostsLocation" className={overlayToggle[hash === "hostsLocation"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={HostLocation}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Hosts Locations</span>
              </Link>
            </li>
            <li>
              <Link to="/assets/#cloudProvider" className={overlayToggle[hash === "cloudProvider"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={CloudProviderSvg}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Cloud Provider</span>
              </Link>
            </li>
            <li>
              <Link to="/assets/#openPort" className={overlayToggle[hash === "openPort"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={OpenPorts}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Open Ports</span>
              </Link>
            </li>
            <li>
              <Link to="/assets/#operatingSystem" className={overlayToggle[hash === "operatingSystem"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={OpertingSystem}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Operating Systems</span>
              </Link>
            </li>
            <li>
              <Link to="/assets/#networkService" className={overlayToggle[hash === "networkService"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={networkService}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>Network Services</span>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_2">
                <span>Asset Monitoring</span>
              </a>
            </li>
          </ul>
        </div>
      );
    }
    else {
      return (
        <div class="side_bar side_bar_brand">
          <ul>
            <li>
              <a href="javascript:void(0)" class="menu_btnside">
                <img src="/assets/images/image-gallery/MenuIcon.png" class="img-fluid" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_1">
                <span>Breach Detection</span>
              </a>
            </li>
            <li>
              <Link to="/breach/stolen-credentials" className={overlayToggle[pathname === "/breach/stolen-credentials" || pathname === "/breach"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={Stolen}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Stolen Credentials</span>
              </Link>
            </li>
            <li>
              <Link to="/breach/malware-credentials" className={overlayToggle[pathname === "/breach/malware-credentials"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/image-gallery/malware_infected_computers.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Malware Infected Computers</span>
              </Link>
            </li>
            <li>
              <Link to="/breach/source-code" className={overlayToggle[pathname === "/breach/source-code"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={Source}
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Source Code Leakage</span>
              </Link>
            </li>
            <li>
              <Link to="/breach/leaked-sessions" className={overlayToggle[pathname === "/breach/leaked-sessions"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/image-gallery/leaked_session.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Leaked Session</span>
              </Link>
            </li>
            <li>
              <Link to="/breach/cloud-bucket" className={overlayToggle[pathname === "/breach/cloud-bucket"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src="/assets/images/image-gallery/open_cloud_buckets.svg"
                      class="img-fluid"

                    />
                  </div>
                </div>
                <span>Open Cloud Buckets</span>
              </Link>
            </li>
            <li>
              <Link to="/breach/ip-blacklist" className={overlayToggle[pathname === "/breach/ip-blacklist"]}>
                <div class="circle_button">
                  <div class="inner_box">
                    <img
                      src={Black}
                      class="img-fluid"
                    />
                  </div>
                </div>
                <span>IP Blacklists</span>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)" class="security_check_2">
                <span>Breach Detection</span>
              </a>
            </li>
          </ul>
        </div>
      );
    }
  } else {
    return (
      <div class="side_bar">
        <ul>
          <li>
            <a href="javascript:void(0)" class="menu_btnside">
              <img src="/assets/images/image-gallery/MenuIcon.png" class="img-fluid" />
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" class="security_check_1">
              <span>User</span>
            </a>
          </li>
          <li>
            <Link to="/user" className={overlayToggle[pathname === "/user" || pathname === "/brand"]}>
              <div class="circle_button">
                <div class="inner_box">
                  <img
                    src="/assets/images/image-gallery/Icon.svg"
                    class="img-fluid"

                  />
                </div>
              </div>
              <span>User</span>
            </Link>
          </li>
          <li>
            <a href="javascript:void(0)" class="security_check_2">
              <span></span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
};

export default Sidenav;
